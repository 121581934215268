/*
 *
 * Administration constants
 *
 */

export const userTypeOptions = () => {
  return [
    { value: 1, label: 'Owner' },
    {
      value: 2,
      label: 'Inkblot Admin',
    },
  ];
};

export const FETCH_ADMIN_MEMBERS = 'app/Administration/FETCH_ADMIN_MEMBERS';
export const FETCH_ADMIN_MEMBERS_ERROR =
  'app/Administration/FETCH_ADMIN_MEMBERS_ERROR';
export const FETCH_ADMIN_MEMBERS_SUCCESS =
  'app/Administration/FETCH_ADMIN_MEMBERS_SUCCESS';

export const CREATE_ADMIN_MEMBER = 'app/Administration/CREATE_ADMIN_MEMBER';
export const CREATE_ADMIN_MEMBER_ERROR =
  'app/Administration/CREATE_ADMIN_MEMBER_ERROR';
export const CREATE_ADMIN_MEMBER_SUCCESS =
  'app/Administration/CREATE_ADMIN_MEMBER_SUCCESS';

export const FETCH_ADMIN_MEMBER = 'app/Administration/FETCH_ADMIN_MEMBER';
export const FETCH_ADMIN_MEMBER_ERROR =
  'app/Administration/FETCH_ADMIN_MEMBER_ERROR';
export const FETCH_ADMIN_MEMBER_SUCCESS =
  'app/Administration/FETCH_ADMIN_MEMBER_SUCCESS';

export const UPDATE_ADMIN_MEMBER = 'app/Administration/UPDATE_ADMIN_MEMBER';
export const UPDATE_ADMIN_MEMBER_ERROR =
  'app/Administration/UPDATE_ADMIN_MEMBER_ERROR';
export const UPDATE_ADMIN_MEMBER_SUCCESS =
  'app/Administration/UPDATE_ADMIN_MEMBER_SUCCESS';

export const DESTROY_ADMIN_MEMBER = 'app/Administration/DESTROY_ADMIN_MEMBER';
export const DESTROY_ADMIN_MEMBER_ERROR =
  'app/Administration/DESTROY_ADMIN_MEMBER_ERROR';
export const DESTROY_ADMIN_MEMBER_SUCCESS =
  'app/Administration/DESTROY_ADMIN_MEMBER_SUCCESS';
